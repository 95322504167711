<template>
<div :class="'container '+menu_color">
    <!-- {{ newVal }} -->
<div class="row">
<div class="contentz" id="main-content">
<div class="book" id="anim_page">
<div id="page-1" @click="goToFirstPage" :class="'page no-anim'">
    
            <!-- .side-1 -->
<div class="side-2" id="p2">
  <div class="content"></div>
              <!-- .content -->
  </div>
            <!-- .side-2 -->
</div>
          <!-- .page -->
<div v-if="showDiv">
<div id="page-2" class="page no-anim">
    <div class="side-1" id="p3">
      <div class="content container">
                <!-- <img src="../assets/image/scrw.svg" class="scrw" />
                <img src="../assets/image/scrw.svg" class="scrw2" /> -->
    <div class="row">
<div class="left-1" v-if="$website_lang === 'ar'">
  <p class="text-center mt-5">
  <img src="../assets/image/f-logo.png" style="width: 120px" />
  </p>
  <p class="text-center main-fonts">
  <i>ذات مرة في قطر، كانت هناك فريات - فتحات على الجدران المشتركة بين المنازل التقليدية، تربط الجيران والأحباء. كانت الزيارات الاجتماعية ومشاركة الهدايا والطعام من الممارسات العزيزة، مما عزز الشعور العميق بالانتماء للمجتمع.</i>
  </p>
<p class="text-center main-fonts">
   <i>ومن وحي هذا التراث، بدأت رحلة فريا. من خلال الرحلات والتجارب، ألهمنا تقديم رؤية حديثة للتقاليد. قادنا شغفنا بالحلويات الباريسية الرائعة إلى السعي لتحدي أنفسنا لخلق تجربة المطعم المثالية - تجربة تُبهج الحواس وتحتفل بقيمنا الأساسية. يقع مقرنا في قطر، ونحن نفخر بكوننا شركة محلية تجسد المعايير الدولية، وتغرس الثقة في عملائنا عندما يكتشفون ويحتضنون إمكاناتهم الحقيقية.</i>
</p>
<p class="text-center main-fonts">
 <i>نحن نؤمن بالانسجام التام بين التاريخ والحداثة، والطبيعة والأناقة، والثقافة القطرية، والحلويات الفرنسية.
تطمح Feryah إلى أن تكون Feryah في العصر الحديث، حيث تجمع الناس معًا من خلال حبنا المشترك للطعام الاستثنائي والقهوة وفن الضيافة.</i>
 </p>
 <p class="text-center main-fonts">
   <i>انضم إلينا في هذه الرحلة لتجربة Feryah - العلامة التجارية التي تجسد التميز، وتحتفي بالتقاليد، وتجمع المجتمعات معًا.</i>
</p>
</div>
<div class="left-1" v-if="$website_lang === 'en'">

<!-- <p class="text-center main-fonts" style="line-height: 24px;"> -->
<p class="text-left main-fonts" style="line-height: 24px;">
<i>Once upon a time in Qatar, there were Feryahs - openings on shared
                        walls between traditional houses, connecting neighbors and loved
                        ones. Social visits and the sharing of gifts and food were cherished
                        practices, fostering a deep sense of community.</i>
</p>
<!-- <p class="text-center main-fonts" style="line-height: 24px;"> -->
  <p class="text-left main-fonts" style="line-height: 24px;">
   <i>Inspired by this heritage, Feryah&#39;s journey began. Through travels
                        and experiences, we were inspired to bring a modern vision to
                        tradition. Our passion for exquisite Parisian patisserie led us on a
                        quest to challenge ourselves to create the ultimate restaurant
                        experience - one that delights the senses and celebrates our core
                        values. Nestled in Qatar, we take pride in being a local business that
                        embodies international standards, instilling confidence in our
                        customers as they discover and embrace their true potential.</i>
</p>
<!-- <p class="text-center main-fonts" style="line-height: 24px;"> -->
  <p class="text-left main-fonts" style="line-height: 24px;">
                      <i>We believe in the perfect harmony of history and modernity, nature
                        and elegance, Qatari culture, and French pâtisserie. Feryah aspires
                        to be a modern-day Feryah, bringing people together through our
                        shared love of exceptional food, coffee, and the art of hospitality.</i>
 </p>
 <!-- <p class="text-center main-fonts" style="line-height: 24px;"> -->
  <p class="text-left main-fonts" style="line-height: 24px;">
   <i>Join us on this journey of experiencing Feryah - a brand that embodies excellence, celebrates tradition, and brings communities
     together.</i>
</p>
<p class="text-center mt-5 img-logo" style="width: 120px;">
     <!-- <img src="../assets/image/f-logo.png" style="width: 120px" /> -->
 </p>
</div>
  </div>
</div>
<!-- .content -->
</div>
 <!-- .side-1 -->
<div class="side-2" id="p4">
<div class="content"></div>
              <!-- .content -->
</div>
<!-- .side-2 -->
</div>
<!--- page3 ---->

<div id='page-3' class="page no-anim">
  <div class="side-1" id="p5">
<div class="content container page-bg">
  <div class="row">
<div class="left-1" @scroll="handleScroll" ref="scrollableDiv">

 

 


                    <!-- {{ categories }} -->
<v-expansion-panels class="mb-6">
<v-expansion-panel v-for="(item, index) in categories" :key="index" variant="accordion">
 
<v-expansion-panel-title :id="'flush-heading' + index" variant="accordion" class="v-panel-wake rotate" :class="(categories.length == 1) ? '' : { 'collapsed': activeIndex !== index }" 
        :data-target="'#flush-collapse' + index" :aria-expanded="activeIndex === index" :aria-controls="'flush-collapse' + index" @click="toggleRotate">
        
 <h3 v-if="$website_lang === 'en'">{{ item.name }}</h3>
 <h5 v-if="$website_lang === 'en'" class="text-center">{{ item.short_desc }}</h5>
 <h3 v-if="$website_lang === 'ar'">{{ item.name_ar }}</h3>
 <h5 v-if="$website_lang === 'ar'" class="text-center">{{ item.short_desc_ar }}</h5>
 
</v-expansion-panel-title>
<transition name="slide-fade">
<v-expansion-panel-text variant="accordion">         
<div :id="'flush-collapse' + index" class="" :class="(categories.length == 1) ? 'show' : { 'show': activeIndex === index }" :aria-labelledby="'flush-heading' + index"
    data-parent="#accordionFlushExample">
<div class="menu-list dot_box" v-if="item.is_category_addon_exist" style="margin-top: 0px;">
 <h6 class="item-addon-head" v-if="$website_lang === 'en'">{{ item.category_addon_title }}</h6>
 <h6 class="item-addon-head" v-if="$website_lang === 'ar'">{{ item.category_addon_title_ar }}</h6>
 <ul v-for="(catAddOn, catAddOnindexs) in item.category_add_ons" :key="catAddOnindexs">
 <li v-if="$website_lang === 'en'">{{ catAddOn.desc }}<span style="font-size: 14px;">{{ formatNumber(catAddOn.price) }}</span></li>
 <li v-if="$website_lang === 'ar'">{{ catAddOn.desc_ar }}{{ formatNumber(catAddOn.price) }}</li>
 </ul>
</div>
<div v-if="$website_lang === 'en'">
<div class="menu-list" v-for="(items, indexs) in item.category_items" :key="indexs">
<div v-if="items.is_item_have_multiple == 1">
<h5 class="main_head">{{ items.name }}
<br>
<span class="jm" v-if="items.is_signature_item == 1">{{ items.signature_chef }}</span>
</h5>
<h5 class="menu-margin " v-for="(menu_items, menu_indexs) in items.menu_sub_items" :key="menu_indexs">
  <img v-if="menu_items.item_icon_url" :src="menu_items.item_icon_url" class="menu-icon">
<span>&nbsp;{{ menu_items.name }} </span>
            <small class="prizee">          
{{ formatNumber(menu_items.price) }}</small>          
<p class=" btn-spc" v-if="menu_items" @click="openModal(menu_items)">
  <button v-if="menu_indexs == 0" class="viewbtn m0 ">View</button>
  <img v-if="menu_items.item_image_url && menu_indexs != 0" :src="menu_items.item_image_url" alt="image" width="50">
  

</p>
 </h5>
    </div>
    <div v-else>
      <h5 class="main_head"> {{ items.special_title }}</h5>
      <h5 class=" "> <img v-if="items.item_icon_url" :src="items.item_icon_url" class="menu-icon">&nbsp;{{
        items.name }} </h5>
      <p class="  main-fonts m0">
        {{ items.desc }}<span style="font-size: 14px;">{{ formatNumber(items.price) }}</span>
      </p>
      <div v-if="items.is_item_have_addon == 1" class="dot_box">
        <h6 class="item-addon-head">{{ items.item_addon_title }}</h6>
        <ul v-for="(addon, addonIndex) in items.menu_item_add_ons" :key="addonIndex">
          <li>{{ addon.desc }}<span>{{ formatNumber(addon.price) }} </span></li>

        </ul>
      </div>
      <p v-if="items.item_image_url" class=" m0 text-center" @click="
        openModal(items)
        ">
        <!-- <button class="viewbtn" v-if="$website_lang === 'ar'">منظر</button>
<button class="viewbtn" v-if="$website_lang === 'en'">View</button> -->
<img v-if="items.item_image_url" :src="items.item_image_url" alt="image" width="50">



      </p>
    </div>
  </div>
</div>
<div v-if="$website_lang === 'ar'">
<div class="menu-list" v-for="(items, indexs) in item.category_items" :key="indexs">
<div v-if="items.is_item_have_multiple == 1">
<h5>{{ items.name_ar }}
<br>
<span class="jm" v-if="items.is_signature_item == 1">{{ items.signature_chef_ar }}</span>
</h5>
<h5 class="menu-margin " v-for="(menu_items, menu_indexs) in items.menu_sub_items"
:key="menu_indexs">
<span> {{ menu_items.name_ar }} <small>
 {{ formatNumber(menu_items.price) }}&nbsp;<img v-if="menu_items.item_icon_url"
:src="menu_items.item_icon_url"></small></span>
</h5>
<p class=" m0 " v-if="menu_items" @click="openModal(menu_items)"><button class="viewbtn">View</button></p>
</div>
<div v-else>
<h5 class="main_head"> {{ items.special_title_ar }}</h5>
  <h5 class=" ">{{
  items.name_ar }}  &nbsp;<img v-if="items.item_icon_url" :src="items.item_icon_url"></h5>
  <p class="  main-fonts m0"> {{ items.desc_ar }}{{ formatNumber(items.price) }}
</p>
<div v-if="items.is_item_have_addon == 1" >
<h6 class=" ">{{ items.item_addon_title_ar }}</h6>
<ul v-for="(addon, addonIndex) in items.menu_item_add_ons" :key="addonIndex">
 <li>{{ addon.desc_ar }} {{ formatNumber(addon.price) }}</li>
</ul>
</div>
<p v-if="items.item_image_url" class="m0 " @click=" openModal(items)"><button class="viewbtn" v-if="$website_lang === 'ar'">منظر</button>
<button class="viewbtn" v-if="$website_lang === 'en'">View</button>
</p>
</div>
</div>
</div>
<div class="menu-list" v-if="item.footer_desc && $website_lang === 'en'">
  <p class="text-footer"><small>{{ item.footer_desc }} </small></p>
</div>
<div class="menu-list" v-if="item.footer_desc_ar && $website_lang === 'ar'">
  <p class="text-footer"><small>{{ item.footer_desc_ar }}</small></p>
</div>
</div>        
</v-expansion-panel-text>
  </Transition>
</v-expansion-panel>
</v-expansion-panels>
<a id="button-arrow" @click="scollToTop('scrollableDiv')">
<img src="../assets/image/down.svg" style="    width: 28px;">
</a>
<p class="text-center img_btm page-logo">
  <!-- <img src="../assets/image/f-logo.png" style="width: 100px;"> -->
</p>
</div>
</div>
</div>
</div>
<div class="side-2" id="p6">
<div class="content"></div>
</div>
</div>


 <!--- page3 ---->
<div id="page-4" class="page no-anim">
<div class="side-1" id="p7">
<div class="content container page-bg">
  <div class="row">
    <div class="left-1" @scroll="handleScroll" ref="scrollableDiv4">
                    <!-- {{ categories }} -->
  <div class="accordion accordion-flush" id="accordionFlushExample">
                      <!-- Accordion Item 1 -->
     <div class="accordion-item" v-for="(item, index) in categories" :key="index">
 <h2 class="accordion-header" :id="'flush-heading' + index">
 <button class="accordion-button" type="button" 


 :class="{
    'non-colored-button': categories.length == 1,
    'collapsed': categories.length != 1 && activeIndex !== index
  }"

  data-toggle="collapse" :data-target="'#flush-collapse' + index"
:aria-expanded="activeIndex === index" :aria-controls="'flush-collapse' + index"
@click="toggleAccordion(index,'scrollableDiv4')">
<h3 v-if="$website_lang === 'en'">{{ item.name }}</h3>
  <h5 v-if="$website_lang === 'en'" class="text-center">{{ item.short_desc }}</h5>
  <h3 v-if="$website_lang === 'ar'">{{ item.name_ar }}</h3>
<h5 v-if="$website_lang === 'ar'" class="text-center">{{ item.short_desc_ar }}</h5>
 </button>
  </h2>
   <div :id="'flush-collapse' + index" class="accordion-collapse collapse"
   :class="(categories.length == 1) ? 'show' : { 'show': activeIndex === index }" :aria-labelledby="'flush-heading' + index"
    data-parent="#accordionFlushExample"> <div class="accordion-body">
 <div class="">
<div class="menu-list" v-if="item.is_category_addon_exist" style="margin-top: 0px;">
<h6 class=" " v-if="$website_lang === 'en'">{{ item.category_addon_title }}</h6>
 <h6 class=" " v-if="$website_lang === 'ar'">{{ item.category_addon_title_ar }}</h6>
 <ul v-for="(catAddOn, catAddOnindexs) in item.category_add_ons" :key="catAddOnindexs">
<li v-if="$website_lang === 'en'">{{ catAddOn.desc }} {{ formatNumber(catAddOn.price) }}</li>
<li v-if="$website_lang === 'ar'">{{ catAddOn.desc_ar }} {{ formatNumber(catAddOn.price) }}</li>
                                </ul>
                              </div>
                              <div v-if="$website_lang === 'en'">
                                <div class="menu-list" v-for="(items, indexs) in item.category_items" :key="indexs">

                                  <div v-if="items.is_item_have_multiple == 1">
                                    <h5>{{ items.name }} 
                                      <br>
                                      <span class="jm" v-if="items.is_signature_item == 1">{{ items.signature_chef
                                      }}</span>
                                    </h5>

                                    <h5 class="menu-margin " v-for="(menu_items, menu_indexs) in items.menu_sub_items"
                                      :key="menu_indexs">
                                      <span> <img v-if="menu_items.item_icon_url"
                                          :src="menu_items.item_icon_url">&nbsp;&nbsp;{{ menu_items.name }} <small>
                                          {{ formatNumber(menu_items.price) }}</small></span>
                                    </h5>
                                    <p class=" m0 " v-if="menu_items" @click="openModal(menu_items)"><button
                                        class="viewbtn">View</button></p>

                                  </div>
                                  <div v-else>
                                    <h5 class="main_head"> {{ items.special_title }}</h5>
                                    <h5 class=" "> <img v-if="items.item_icon_url" :src="items.item_icon_url">&nbsp;{{
                                      items.name }} </h5>
                                    <p class="  main-fonts m0">
                                      {{ items.desc }} &nbsp; <span style="font-size: 14px;">{{ formatNumber(items.price) }}</span>
                                    </p>
                                    <div v-if="items.is_item_have_addon == 1">
                                      <h6 class=" ">{{ items.item_addon_title }}</h6>
                                      <ul v-for="(addon, addonIndex) in items.menu_item_add_ons" :key="addonIndex">
                                        <li>{{ addon.desc }} {{ formatNumber(addon.price) }}</li>

                                      </ul>
                                    </div>
                                    <p v-if="items.item_image_url" class=" m0 " @click="
                                      openModal(items)
                                      "><button class="viewbtn" v-if="$website_lang === 'ar'">منظر</button>
<button class="viewbtn" v-if="$website_lang === 'en'">View</button>
                                    </p>
                                  </div>



                                </div>
                              </div>
                              <div v-if="$website_lang === 'ar'">
                                <div class="menu-list" v-for="(items, indexs) in item.category_items" :key="indexs">

                                  <div v-if="items.is_item_have_multiple == 1">
                                    <h5>{{ items.name_ar }}
                                      <br>
                                      <span class="jm" v-if="items.is_signature_item == 1">{{ items.signature_chef_ar
                                      }}</span>
                                    </h5>

                                    <h5 class="menu-margin " v-for="(menu_items, menu_indexs) in items.menu_sub_items"
                                      :key="menu_indexs">
                                      <span> {{ menu_items.name_ar }} <small>
                                          {{ formatNumber(menu_items.price) }}&nbsp;<img v-if="menu_items.item_icon_url"
                                          :src="menu_items.item_icon_url"></small></span>
                                    </h5>
                                    <p class=" m0 " v-if="menu_items" @click="openModal(menu_items)"><button
                                        class="viewbtn">View</button></p>

                                  </div>
                                  <div v-else>
                                    <h5 class="main_head"> {{ items.special_title_ar }}</h5>
                                    <h5 class=" ">{{
                                      items.name_ar }}  &nbsp;<img v-if="items.item_icon_url" :src="items.item_icon_url"></h5>
                                    <p class="  main-fonts m0">
                                      {{ items.desc_ar }} {{ formatNumber(items.price) }}
                                    </p>
                                    <div v-if="items.is_item_have_addon == 1">
                                      <h6 class=" ">{{ items.item_addon_title_ar }}</h6>
                                      <ul v-for="(addon, addonIndex) in items.menu_item_add_ons" :key="addonIndex">
                                        <li>{{ addon.desc_ar }} {{ formatNumber(addon.price) }}</li>

                                      </ul>
                                    </div>
                                    <p v-if="items.item_image_url" class=" m0 " @click="
                                      openModal(items)
                                      "><button class="viewbtn" v-if="$website_lang === 'ar'">منظر</button>
<button class="viewbtn" v-if="$website_lang === 'en'">View</button>
                                    </p>
                                  </div>



                                </div>
                              </div>

                              <div class="menu-list" v-if="item.footer_desc && $website_lang === 'en'">
                                <p class="text-footer"><small>{{ item.footer_desc }}</small></p>
                              </div>
                              <div class="menu-list" v-if="item.footer_desc_ar && $website_lang === 'ar'">
                                <p class="text-footer"><small>{{ item.footer_desc_ar }}</small></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a id="button-arrow4" @click="scollToTop('scrollableDiv4')">
                      <img src="../assets/image/down.svg" style="    width: 28px;">
                    </a>
                    <p class="text-center img_btm pageone-logo">
                      <!-- <img src="../assets/image/f-logo.png" style="width: 100px;"> -->
                    </p>
                  </div>
                </div>
              </div>
              <!-- .content -->
</div>
            <!-- .side-1 -->
<div class="side-2" id="p8">
<div class="content"></div>
              <!-- .content -->
</div>
<!-- .side-2 -->
</div>
  <!-- #page-4 -->
 <!--- page5---->
  <div id="page-5" class="page no-anim">
<div class="side-1" id="p7">
<div class="content container page-bg">
  <div class="row">
    <div class="left-1" @scroll="handleScroll" ref="scrollableDiv5">
                    <!-- {{ categories }} -->
  <div class="accordion accordion-flush" id="accordionFlushExample">
                      <!-- Accordion Item 1 -->
     <div class="accordion-item" v-for="(item, index) in categories" :key="index">
 <h2 class="accordion-header" :id="'flush-heading' + index">
 <button class="accordion-button" type="button" 


 :class="{
    'non-colored-button': categories.length == 1,
    'collapsed': categories.length != 1 && activeIndex !== index
  }"

  data-toggle="collapse" :data-target="'#flush-collapse' + index"
:aria-expanded="activeIndex === index" :aria-controls="'flush-collapse' + index"
@click="toggleAccordion(index,'scrollableDiv4')">
<h3 v-if="$website_lang === 'en'">{{ item.name }}</h3>
  <h5 v-if="$website_lang === 'en'" class="text-center">{{ item.short_desc }}</h5>
  <h3 v-if="$website_lang === 'ar'">{{ item.name_ar }}</h3>
<h5 v-if="$website_lang === 'ar'" class="text-center">{{ item.short_desc_ar }}</h5>
 </button>
  </h2>
   <div :id="'flush-collapse' + index" class="accordion-collapse collapse"
   :class="(categories.length == 1) ? 'show' : { 'show': activeIndex === index }" :aria-labelledby="'flush-heading' + index"
    data-parent="#accordionFlushExample"> <div class="accordion-body">
 <div class="">
<div class="menu-list" v-if="item.is_category_addon_exist" style="margin-top: 0px;">
<h6 class=" " v-if="$website_lang === 'en'">{{ item.category_addon_title }}</h6>
 <h6 class=" " v-if="$website_lang === 'ar'">{{ item.category_addon_title_ar }}</h6>
 <ul v-for="(catAddOn, catAddOnindexs) in item.category_add_ons" :key="catAddOnindexs">
<li v-if="$website_lang === 'en'">{{ catAddOn.desc }} {{ formatNumber(catAddOn.price) }}</li>
<li v-if="$website_lang === 'ar'">{{ catAddOn.desc_ar }} {{ formatNumber(catAddOn.price) }}</li>
                                </ul>
                              </div>
                              <div v-if="$website_lang === 'en'">
                                <div class="menu-list" v-for="(items, indexs) in item.category_items" :key="indexs">

                                  <div v-if="items.is_item_have_multiple == 1">
                                    <h5>{{ items.name }} 
                                      <br>
                                      <span class="jm" v-if="items.is_signature_item == 1">{{ items.signature_chef
                                      }}</span>
                                    </h5>

                                    <h5 class="menu-margin " v-for="(menu_items, menu_indexs) in items.menu_sub_items"
                                      :key="menu_indexs">
                                      <span> <img v-if="menu_items.item_icon_url"
                                          :src="menu_items.item_icon_url">&nbsp;&nbsp;{{ menu_items.name }} <small>
                                          {{ formatNumber(menu_items.price) }}</small></span>
                                    </h5>
                                    <p class=" m0 " v-if="menu_items" @click="openModal(menu_items)"><button
                                        class="viewbtn">View</button></p>

                                  </div>
                                  <div v-else>
                                    <h5 class="main_head"> {{ items.special_title }}</h5>
                                    <h5 class=" "> <img v-if="items.item_icon_url" :src="items.item_icon_url">&nbsp;{{
                                      items.name }} </h5>
                                    <p class="  main-fonts m0">
                                      {{ items.desc }} &nbsp; <span style="font-size: 14px;">{{ formatNumber(items.price) }}</span>
                                    </p>
                                    <div v-if="items.is_item_have_addon == 1">
                                      <h6 class=" ">{{ items.item_addon_title }}</h6>
                                      <ul v-for="(addon, addonIndex) in items.menu_item_add_ons" :key="addonIndex">
                                        <li>{{ addon.desc }} {{ formatNumber(addon.price) }}</li>

                                      </ul>
                                    </div>
                                    <p v-if="items.item_image_url" class=" m0 " @click="
                                      openModal(items)
                                      "><button class="viewbtn" v-if="$website_lang === 'ar'">منظر</button>
<button class="viewbtn" v-if="$website_lang === 'en'">View</button>
                                    </p>
                                  </div>



                                </div>
                              </div>
                              <div v-if="$website_lang === 'ar'">
                                <div class="menu-list" v-for="(items, indexs) in item.category_items" :key="indexs">

                                  <div v-if="items.is_item_have_multiple == 1">
                                    <h5>{{ items.name_ar }}
                                      <br>
                                      <span class="jm" v-if="items.is_signature_item == 1">{{ items.signature_chef_ar
                                      }}</span>
                                    </h5>

                                    <h5 class="menu-margin " v-for="(menu_items, menu_indexs) in items.menu_sub_items"
                                      :key="menu_indexs">
                                      <span> {{ menu_items.name_ar }} <small>
                                          {{ formatNumber(menu_items.price) }}&nbsp;<img v-if="menu_items.item_icon_url"
                                          :src="menu_items.item_icon_url"></small></span>
                                    </h5>
                                    <p class=" m0 " v-if="menu_items" @click="openModal(menu_items)"><button
                                        class="viewbtn">View</button></p>

                                  </div>
                                  <div v-else>
                                    <h5 class="main_head"> {{ items.special_title_ar }}</h5>
                                    <h5 class=" ">{{
                                      items.name_ar }}  &nbsp;<img v-if="items.item_icon_url" :src="items.item_icon_url"></h5>
                                    <p class="  main-fonts m0">
                                      {{ items.desc_ar }} {{ formatNumber(items.price) }}
                                    </p>
                                    <div v-if="items.is_item_have_addon == 1">
                                      <h6 class=" ">{{ items.item_addon_title_ar }}</h6>
                                      <ul v-for="(addon, addonIndex) in items.menu_item_add_ons" :key="addonIndex">
                                        <li>{{ addon.desc_ar }} {{ formatNumber(addon.price) }}</li>

                                      </ul>
                                    </div>
                                    <p v-if="items.item_image_url" class=" m0 " @click="
                                      openModal(items)
                                      "><button class="viewbtn" v-if="$website_lang === 'ar'">منظر</button>
<button class="viewbtn" v-if="$website_lang === 'en'">View</button>
                                    </p>
                                  </div>



                                </div>
                              </div>

                              <div class="menu-list" v-if="item.footer_desc && $website_lang === 'en'">
                                <p class="text-footer"><small>{{ item.footer_desc }}</small></p>
                              </div>
                              <div class="menu-list" v-if="item.footer_desc_ar && $website_lang === 'ar'">
                                <p class="text-footer"><small>{{ item.footer_desc_ar }}</small></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a id="button-arrow4" @click="scollToTop('scrollableDiv5')">
                      <img src="../assets/image/down.svg" style="    width: 28px;">
                    </a>
                    <p class="text-center img_btm pageone-logo">
                      <!-- <img src="../assets/image/f-logo.png" style="width: 100px;"> -->
                    </p>
                  </div>
                </div>
              </div>
              <!-- .content -->
</div>
            <!-- .side-1 -->
<div class="side-2" id="p8">
<div class="content"></div>
              <!-- .content -->
</div>
<!-- .side-2 -->
</div>






<!--- page6---->





          <div id="page-6" class="page no-anim">
<div class="side-1" id="p7">
<div class="content container page-bg">
  <div class="row">
    <div class="left-1" @scroll="handleScroll" ref="scrollableDiv6">
                    <!-- {{ categories }} -->
  <div class="accordion accordion-flush" id="accordionFlushExample">
                      <!-- Accordion Item 1 -->
     <div class="accordion-item" v-for="(item, index) in categories" :key="index">
 <h2 class="accordion-header" :id="'flush-heading' + index">
 <button class="accordion-button" type="button" 


 :class="{
    'non-colored-button': categories.length == 1,
    'collapsed': categories.length != 1 && activeIndex !== index
  }"

  data-toggle="collapse" :data-target="'#flush-collapse' + index"
:aria-expanded="activeIndex === index" :aria-controls="'flush-collapse' + index"
@click="toggleAccordion(index,'scrollableDiv4')">
<h3 v-if="$website_lang === 'en'">{{ item.name }}</h3>
  <h5 v-if="$website_lang === 'en'" class="text-center">{{ item.short_desc }}</h5>
  <h3 v-if="$website_lang === 'ar'">{{ item.name_ar }}</h3>
<h5 v-if="$website_lang === 'ar'" class="text-center">{{ item.short_desc_ar }}</h5>
 </button>
  </h2>
   <div :id="'flush-collapse' + index" class="accordion-collapse collapse"
   :class="(categories.length == 1) ? 'show' : { 'show': activeIndex === index }" :aria-labelledby="'flush-heading' + index"
    data-parent="#accordionFlushExample"> <div class="accordion-body">
 <div class="">
<div class="menu-list" v-if="item.is_category_addon_exist" style="margin-top: 0px;">
<h6 class=" " v-if="$website_lang === 'en'">{{ item.category_addon_title }}</h6>
 <h6 class=" " v-if="$website_lang === 'ar'">{{ item.category_addon_title_ar }}</h6>
 <ul v-for="(catAddOn, catAddOnindexs) in item.category_add_ons" :key="catAddOnindexs">
<li v-if="$website_lang === 'en'">{{ catAddOn.desc }} {{ formatNumber(catAddOn.price) }}</li>
<li v-if="$website_lang === 'ar'">{{ catAddOn.desc_ar }} {{ formatNumber(catAddOn.price) }}</li>
                                </ul>
                              </div>
                              <div v-if="$website_lang === 'en'">
                                <div class="menu-list" v-for="(items, indexs) in item.category_items" :key="indexs">

                                  <div v-if="items.is_item_have_multiple == 1">
                                    <h5>{{ items.name }} 
                                      <br>
                                      <span class="jm" v-if="items.is_signature_item == 1">{{ items.signature_chef
                                      }}</span>
                                    </h5>

                                    <h5 class="menu-margin " v-for="(menu_items, menu_indexs) in items.menu_sub_items"
                                      :key="menu_indexs">
                                      <span> <img v-if="menu_items.item_icon_url"
                                          :src="menu_items.item_icon_url">&nbsp;&nbsp;{{ menu_items.name }} <small>
                                          {{ formatNumber(menu_items.price) }}</small></span>
                                    </h5>
                                    <p v-if="menu_items" class=" m0 " @click="openModal(menu_items)"><button
                                        class="viewbtn"> View</button></p>

                                  </div>
                                  <div v-else>
                                    <h5 class="main_head"> {{ items.special_title }}</h5>
                                    <h5 class=" "> <img v-if="items.item_icon_url" :src="items.item_icon_url">&nbsp;{{
                                      items.name }} </h5>
                                    <p class="  main-fonts m0">
                                      {{ items.desc }} &nbsp; <span style="font-size: 14px;">{{ formatNumber(items.price) }}</span>
                                    </p>
                                    <div v-if="items.is_item_have_addon == 1">
                                      <h6 class=" ">{{ items.item_addon_title }}</h6>
                                      <ul v-for="(addon, addonIndex) in items.menu_item_add_ons" :key="addonIndex">
                                        <li>{{ addon.desc }} {{ formatNumber(addon.price) }}</li>

                                      </ul>
                                    </div>
                                    <p v-if="items.item_image_url" class=" m0 " @click="
                                      openModal(items)
                                      "><button class="viewbtn" v-if="$website_lang === 'ar'">منظر</button>
<button class="viewbtn" v-if="$website_lang === 'en'">View</button>
                                    </p>
                                  </div>



                                </div>
                              </div>
                              <div v-if="$website_lang === 'ar'">
                                <div class="menu-list" v-for="(items, indexs) in item.category_items" :key="indexs">

                                  <div v-if="items.is_item_have_multiple == 1">
                                    <h5>{{ items.name_ar }}
                                      <br>
                                      <span class="jm" v-if="items.is_signature_item == 1">{{ items.signature_chef_ar
                                      }}</span>
                                    </h5>

                                    <h5 class="menu-margin " v-for="(menu_items, menu_indexs) in items.menu_sub_items"
                                      :key="menu_indexs">
                                      <span> {{ menu_items.name_ar }} <small>
                                          {{ formatNumber(menu_items.price) }}&nbsp;<img v-if="menu_items.item_icon_url"
                                          :src="menu_items.item_icon_url"></small></span>
                                    </h5>
                                    <p v-if="menu_items" class=" m0 " @click="openModal(menu_items)"><button
                                        class="viewbtn">View</button></p>

                                  </div>
                                  <div v-else>
                                    <h5 class="main_head"> {{ items.special_title_ar }}</h5>
                                    <h5 class=" ">{{
                                      items.name_ar }}  &nbsp;<img v-if="items.item_icon_url" :src="items.item_icon_url"></h5>
                                    <p class="  main-fonts m0">
                                      {{ items.desc_ar }} {{ formatNumber(items.price) }}
                                    </p>
                                    <div v-if="items.is_item_have_addon == 1">
                                      <h6 class=" ">{{ items.item_addon_title_ar }}</h6>
                                      <ul v-for="(addon, addonIndex) in items.menu_item_add_ons" :key="addonIndex">
                                        <li>{{ addon.desc_ar }} {{ formatNumber(addon.price) }}</li>

                                      </ul>
                                    </div>
                                    <p v-if="items.item_image_url" class=" m0 " @click="
                                      openModal(items)
                                      "><button class="viewbtn" v-if="$website_lang === 'ar'">منظر</button>
<button class="viewbtn" v-if="$website_lang === 'en'">View</button>
                                    </p>
                                  </div>



                                </div>
                              </div>

                              <div class="menu-list" v-if="item.footer_desc && $website_lang === 'en'">
                                <p class="text-footer"><small>{{ item.footer_desc }}</small></p>
                              </div>
                              <div class="menu-list" v-if="item.footer_desc_ar && $website_lang === 'ar'">
                                <p class="text-footer"><small>{{ item.footer_desc_ar }}</small></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a id="button-arrow4" @click="scollToTop('scrollableDiv6')">
                      <img src="../assets/image/down.svg" style="    width: 28px;">
                    </a>
                    <p class="text-center img_btm pageone-logo">
                      <!-- <img src="../assets/image/f-logo.png" style="width: 100px;"> -->
                    </p>
                  </div>
                </div>
              </div>
              <!-- .content -->
</div>
            <!-- .side-1 -->
<div class="side-2" id="p8">
<div class="content"></div>
              <!-- .content -->
</div>
<!-- .side-2 -->
</div>




<!--- page6---->
 

          <div id="page-6" class="page no-anim">
<div class="side-1" id="p7">
<div class="content container page-bg">
  <div class="row">
    <div class="left-1" @scroll="handleScroll" ref="scrollableDiv7">
                    <!-- {{ categories }} -->
  <div class="accordion accordion-flush" id="accordionFlushExample">
                      <!-- Accordion Item 1 -->
     <div class="accordion-item" v-for="(item, index) in categories" :key="index">
 <h2 class="accordion-header" :id="'flush-heading' + index">
 <button class="accordion-button" type="button" 


 :class="{
    'non-colored-button': categories.length == 1,
    'collapsed': categories.length != 1 && activeIndex !== index
  }"

  data-toggle="collapse" :data-target="'#flush-collapse' + index"
:aria-expanded="activeIndex === index" :aria-controls="'flush-collapse' + index"
@click="toggleAccordion(index,'scrollableDiv4')">
<h3 v-if="$website_lang === 'en'">{{ item.name }}</h3>
  <h5 v-if="$website_lang === 'en'" class="text-center">{{ item.short_desc }}</h5>
  <h3 v-if="$website_lang === 'ar'">{{ item.name_ar }}</h3>
<h5 v-if="$website_lang === 'ar'" class="text-center">{{ item.short_desc_ar }}</h5>
 </button>
  </h2>
   <div :id="'flush-collapse' + index" class="accordion-collapse collapse"
   :class="(categories.length == 1) ? 'show' : { 'show': activeIndex === index }" :aria-labelledby="'flush-heading' + index"
    data-parent="#accordionFlushExample"> <div class="accordion-body">
 <div class="">
<div class="menu-list" v-if="item.is_category_addon_exist" style="margin-top: 0px;">
<h6 class=" " v-if="$website_lang === 'en'">{{ item.category_addon_title }}</h6>
 <h6 class=" " v-if="$website_lang === 'ar'">{{ item.category_addon_title_ar }}</h6>
 <ul v-for="(catAddOn, catAddOnindexs) in item.category_add_ons" :key="catAddOnindexs">
<li v-if="$website_lang === 'en'">{{ catAddOn.desc }} {{ formatNumber(catAddOn.price) }}</li>
<li v-if="$website_lang === 'ar'">{{ catAddOn.desc_ar }} {{ formatNumber(catAddOn.price) }}</li>
                                </ul>
                              </div>
                              <div v-if="$website_lang === 'en'">
                                <div class="menu-list" v-for="(items, indexs) in item.category_items" :key="indexs">

                                  <div v-if="items.is_item_have_multiple == 1">
                                    <h5>{{ items.name }} 
                                      <br>
                                      <span class="jm" v-if="items.is_signature_item == 1">{{ items.signature_chef
                                      }}</span>
                                    </h5>

                                    <h5 class="menu-margin " v-for="(menu_items, menu_indexs) in items.menu_sub_items"
                                      :key="menu_indexs">
                                      <span> <img v-if="menu_items.item_icon_url"
                                          :src="menu_items.item_icon_url">&nbsp;&nbsp;{{ menu_items.name }} <small>
                                          {{ formatNumber(menu_items.price) }}</small></span>
                                    </h5>
                                    <p class=" m0 " v-if="menu_items" @click="openModal(menu_items)"><button
                                        class="viewbtn">View</button></p>

                                  </div>
                                  <div v-else>
                                    <h5 class="main_head"> {{ items.special_title }}</h5>
                                    <h5 class=" "> <img v-if="items.item_icon_url" :src="items.item_icon_url">&nbsp;{{
                                      items.name }} </h5>
                                    <p class="  main-fonts m0">
                                      {{ items.desc }} &nbsp; <span style="font-size: 14px;">{{ formatNumber(items.price) }}</span>
                                    </p>
                                    <div v-if="items.is_item_have_addon == 1">
                                      <h6 class=" ">{{ items.item_addon_title }}</h6>
                                      <ul v-for="(addon, addonIndex) in items.menu_item_add_ons" :key="addonIndex">
                                        <li>{{ addon.desc }} {{ formatNumber(addon.price) }}</li>

                                      </ul>
                                    </div>
                                    <p v-if="items.item_image_url" class=" m0 " @click="
                                      openModal(items)
                                      "><button class="viewbtn" v-if="$website_lang === 'ar'">منظر</button>
<button class="viewbtn" v-if="$website_lang === 'en'">View</button>
                                    </p>
                                  </div>



                                </div>
                              </div>
                              <div v-if="$website_lang === 'ar'">
                                <div class="menu-list" v-for="(items, indexs) in item.category_items" :key="indexs">

                                  <div v-if="items.is_item_have_multiple == 1">
                                    <h5>{{ items.name_ar }}
                                      <br>
                                      <span class="jm" v-if="items.is_signature_item == 1">{{ items.signature_chef_ar
                                      }}</span>
                                    </h5>

                                    <h5 class="menu-margin " v-for="(menu_items, menu_indexs) in items.menu_sub_items"
                                      :key="menu_indexs">
                                      <span> {{ menu_items.name_ar }} <small>
                                          {{ formatNumber(menu_items.price) }}&nbsp;<img v-if="menu_items.item_icon_url"
                                          :src="menu_items.item_icon_url"></small></span>
                                    </h5>
                                    <p class=" m0 " v-if="menu_items" @click="openModal(menu_items)"><button
                                        class="viewbtn">View</button></p>

                                  </div>
                                  <div v-else>
                                    <h5 class="main_head"> {{ items.special_title_ar }}</h5>
                                    <h5 class=" ">{{
                                      items.name_ar }}  &nbsp;<img v-if="items.item_icon_url" :src="items.item_icon_url"></h5>
                                    <p class="  main-fonts m0">
                                      {{ items.desc_ar }} {{ formatNumber(items.price) }}
                                    </p>
                                    <div v-if="items.is_item_have_addon == 1">
                                      <h6 class=" ">{{ items.item_addon_title_ar }}</h6>
                                      <ul v-for="(addon, addonIndex) in items.menu_item_add_ons" :key="addonIndex">
                                        <li>{{ addon.desc_ar }} {{ formatNumber(addon.price) }}</li>

                                      </ul>
                                    </div>
                                    <p v-if="items.item_image_url" class=" m0 " @click="
                                      openModal(items)
                                      "><button class="viewbtn" v-if="$website_lang === 'ar'">منظر</button>
<button class="viewbtn" v-if="$website_lang === 'en'">View</button>
                                    </p>
                                  </div>



                                </div>
                              </div>

                              <div class="menu-list" v-if="item.footer_desc && $website_lang === 'en'">
                                <p class="text-footer"><small>{{ item.footer_desc }}</small></p>
                              </div>
                              <div class="menu-list" v-if="item.footer_desc_ar && $website_lang === 'ar'">
                                <p class="text-footer"><small>{{ item.footer_desc_ar }}</small></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a id="button-arrow4" @click="scollToTop('scrollableDiv7')">
                      <img src="../assets/image/down.svg" style="    width: 28px;">
                    </a>
                    <p class="text-center img_btm pageone-logo">
                      <!-- <img src="../assets/image/f-logo.png" style="width: 100px;"> -->
                    </p>
                  </div>
                </div>
              </div>
              <!-- .content -->
</div>
            <!-- .side-1 -->
<div class="side-2" id="p8">
<div class="content"></div>
              <!-- .content -->
</div>
<!-- .side-2 -->
</div>





        </div>

        </div>
      </div>
      <div class="sidemenu" style="display: none">
        <sidemnu v-if="showDiv" @change-comp="handleCustomEvent" />
      </div>
    </div>
  </div>
  <div :class="'mdl-box '+modal_color" v-if="showModal">
    <div class="mdl sing">
      <button @click="closeModal()" class="mdl_button"><img src="../assets/image/close.svg"
          style="width: 15px;"></button>
      <div v-if="!multiple">
        <img v-if="image.item_image_url" :src="image.item_image_url" class="img-fluid" />

        <p class="text-center"  v-if="$website_lang === 'en'"><span class="view-color"> {{ image.name }} </span></p>
        <p class="text-center"  v-if="$website_lang === 'ar'"><span class="view-color">{{ image.name_ar }} </span></p>
      </div>
     
      <!-- <img src="" class="img-fluid"> -->
      <v-carousel  v-else show-arrows hide-delimiter-background>

        <template v-slot:prev="{ props }">
          <v-btn variant="elevated" color="success" @click="props.onClick">

            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30"
              viewBox="0 0 30 30">
              <defs>
                <clipPath id="clip-left">
                  <rect width="30" height="30" />
                </clipPath>
              </defs>
             

              <g id="arow" clip-path="url(#clip-arow)">
    <path id="Icon_metro-chevron-thin-left" data-name="Icon metro-chevron-thin-left" d="M27.409,34.481a1.338,1.338,0,0,1,0,1.881,1.307,1.307,0,0,1-1.862,0L10.5,21.167a1.338,1.338,0,0,1,0-1.881L25.547,4.09a1.308,1.308,0,0,1,1.862,0,1.338,1.338,0,0,1,0,1.881L13.687,20.227,27.409,34.481Z" transform="translate(-8.955 -3.201)" fill="#fff"/>
  </g>
            </svg>

          </v-btn>
        </template>
        <template v-slot:next="{ props }">
          <v-btn variant="elevated" color="info" @click="props.onClick">

            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30"
              viewBox="0 0 30 30">
              <defs>
                <clipPath id="clip-right">
                  <rect width="30" height="30" />
                </clipPath>
              </defs>
              <g id="arow2" clip-path="url(#clip-arow2)">
    <path id="Icon_metro-chevron-thin-left" data-name="Icon metro-chevron-thin-left" d="M10.5,34.481a1.338,1.338,0,0,0,0,1.881,1.307,1.307,0,0,0,1.862,0l15.046-15.2a1.338,1.338,0,0,0,0-1.881L12.363,4.09a1.308,1.308,0,0,0-1.862,0,1.338,1.338,0,0,0,0,1.881L24.223,20.227,10.5,34.481Z" transform="translate(-6.955 -3.201)" fill="#fff"/>
  </g>
            </svg>

          </v-btn>
        </template>


        <v-carousel-item v-for="(image, index) in multiple_img" :key="index" :src="image.item_image_url">
          <p class="text-center" style="position:absolute" v-if="$website_lang === 'en'"><b> {{ image.name }} </b></p>
          <p class="text-center" style="position:absolute" v-if="$website_lang === 'ar'"><b> {{ image.name_ar }} </b></p>
        </v-carousel-item>
      </v-carousel>

    </div>
  </div>
</template>
<script>
/*eslint-disable */
import sidemnu from "../components/SideMenu.vue";
import $ from "jquery";
import "@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css";
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from "@dafcoe/vue-collapsible-panel";
export default {
  components: {
    sidemnu,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },

  data() {
    return {
      pages: [],
      image: null,
      newVal: 0,
      oldVal: 0,
      countVal: 0,
      showModal: false,
      is_clicked: 0,
      multiple: true,
      accordionItems: [
        { title: 'Wakeup Call', subtitle: 'Bakeries And Viennoiseries', content: 'Content for Wakeup Call', number: 1 },
        { title: 'L\'Eggstravaganzt', subtitle: 'Egg Specialities', content: 'Content for L\'Eggstravaganzt', number: 2 }
      ],
      activeIndex: null,
      showDiv: true,
      categories: [],
      menuList: [],
      multiple_img: [],
      backgroundImageUrl:localStorage.getItem('menu_image'),
      menu_color:localStorage.getItem('menu_color'),
      modal_color:localStorage.getItem('modal_color')

      // showButton: false
      // flag: 0,
    };
  },
  mounted() {
    window.onpopstate = function (event) {
      // console.log(event.state.current);
      window.location.href = event.state.current;
    };

    this.$nextTick(() => {
      this.goToFirstPageAfterDelay();
    });

    if (localStorage.getItem("animationFlag") == 0) {
      // $("#anim_page").addClass("animate__animated");
      // $("#anim_page").addClass("animate__zoomIn");
    }
    localStorage.setItem("animationFlag", 1);
    // console.log(this.$route.params.id);

    // if (this.$route.params.id == 1) {
    //   $("#anim_page").addClass("animate__animated");
    //   $("#anim_page").addClass("animate__zoomIn");
    // }

    // $(".anoob").click(function () {
    //   $("#page-2").removeClass("no-anim");
    //   $("#page-2").addClass("flipped");
    // });

    // $(".anoob-2").click(function () {
    //   $("#page-3").removeClass("no-anim");
    //   $("#page-3").addClass("flipped");
    // });

    // $(".anoob-3").click(function () {
    //   $("#page-4").removeClass("no-anim");
    //   $("#page-4").addClass("flipped");
    // });

    // $(".anoob-4").click(function () {
    //   $("#page-5").removeClass("no-anim");
    //   $("#page-5").addClass("flipped");
    // });

    // $(".page").click(function () {
    //   // alert(1);
    //   $(this).removeClass("no-anim").toggleClass("flipped");
    //   $(".page > div").click(function (e) {
    //     // console.log(e.stopPropagation());
    //     e.stopPropagation();
    //   });
    //   reorder();
    // });
    // this.countVal++;
    function reorder() {
      $(".book").each(function () {
        var pages = $(this).find(".page");
        var pages_flipped = $(this).find(".flipped");
        pages.each(function (i) {
          // console.log(1);
          $(this).css("z-index", pages.length - i);
        });
        pages_flipped.each(function (i) {
          $(this).css("z-index", i + 1);
        });
      });
    }
    reorder();
    // setTimeout(() => {

    // }, 500);

    this.menuList = JSON.parse(localStorage.getItem('menu_data'))
  },

  methods: {
    formatNumber(value) {
      return Number(value);
    },
    toggleAccordion(index,id) {
    
      this.activeIndex = this.activeIndex === index ? null : index;
      if (id == 'scrollableDiv') {
        // this.$refs.scrollableDiv.scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // });
        setTimeout(() => {
      this.$refs.scrollableDiv.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100); 
      }
      if (id == 'scrollableDiv4') {
        this.$refs.scrollableDiv4.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      if (id == 'scrollableDiv5') {
        this.$refs.scrollableDiv5.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      if (id == 'scrollableDiv6') {
        this.$refs.scrollableDiv6.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      if (id == 'scrollableDiv7') {
        this.$refs.scrollableDiv7.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },
    scollToTop(id) {

      if (id == 'scrollableDiv') {
        this.$refs.scrollableDiv.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      if (id == 'scrollableDiv4') {
        this.$refs.scrollableDiv4.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      if (id == 'scrollableDiv5') {
        this.$refs.scrollableDiv5.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      if (id == 'scrollableDiv6') {
        this.$refs.scrollableDiv6.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      if (id == 'scrollableDiv7') {
        this.$refs.scrollableDiv7.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }

    },

    handleScroll(event) {
   
      if (this.$refs.scrollableDiv.scrollTop > 100) {
      
        $("#button-arrow").addClass("show");
        // this.showButton = true;
      } else {
        $("#button-arrow").removeClass("show");
        // this.showButton = false;
      }

      if (this.$refs.scrollableDiv4.scrollTop > 100) {

        $("#button-arrow4").addClass("show");
        // this.showButton = true;
      } else {
        $("#button-arrow4").removeClass("show");
        // this.showButton = false;
      }

      if (this.$refs.scrollableDiv5.scrollTop > 100) {

        $("#button-arrow5").addClass("show");
        // this.showButton = true;
      } else {
        $("#button-arrow5").removeClass("show");
        // this.showButton = false;
      }

      if (this.$refs.scrollableDiv6.scrollTop > 100) {

        $("#button-arrow6").addClass("show");
        // this.showButton = true;
      } else {
        $("#button-arrow6").removeClass("show");
        // this.showButton = false;
      }

      if (this.$refs.scrollableDiv7.scrollTop > 100) {

        $("#button-arrow7").addClass("show");
        // this.showButton = true;
      } else {
        $("#button-arrow7").removeClass("show");
        // this.showButton = false;
      }
    },
    goToFirstPageAfterDelay() {
     
      setTimeout(() => {
        this.giveFlipStyle();
      }, 0);
    },
    giveFlipStyle() {
      if (this.is_clicked == 0) {
       
        $("#page-1")
          .toggleClass("flipped")
          .removeClass("no-anim")
          .css("z-index", 1);
        $(".sidemenu").css("display", "block");
        
        // $("#main-content").removeClass("contentz");
        $("#main-content")
          .toggleClass("contentz-after")
          .removeClass("contentz");
      }
    },
    focusSection() {
      this.$nextTick(() => {
        if (this.$refs.section) {
          this.$refs.section.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
    },
    goToFirstPage() {
      this.is_clicked = 1;
      $("#page-1")
        .toggleClass("flipped")
        .removeClass("no-anim")
        .css("z-index", 1);
      $(".sidemenu").css("display", "block");
      $("#main-content").removeClass("contentz");
      $("#main-content").addClass("contentz-after");
      // alert(1);

      // if (this.countVal === undefined || this.countVal != 1) {
      //   // console.log('f'+this.countVal);
      //   // $("#page-1").removeClass("no-anim").toggleClass("flipped").css("z-index", 1);

      //   // var paragraph = document.getElementById("page-1");

      //   // // Append a class
      //   // paragraph.classList.add("flipped");
      //   alert(0);
      //   this.add = 'flipped';
      //   console.log(this.add);
      // }
    },
    // watch: {
    //   $route(to, from) {
    //     alert(from.path);
    //   },
    // },
    handleCustomEvent(values) {
      this.showModal = false;
      setTimeout(() => {
        this.activeIndex = null
      }, 200);
      var val = values.param

      this.getMenuDetails(values.sectionId);
      if (val === 0) {
        this.showDiv = false;
        $("#page-1")
          .removeClass("flipped")
          .toggleClass("no-anim")
          .css("z-index", 6);

        setTimeout(() => {
          $(".sidemenu").css("display", "none");
          $("#main-content").removeClass("contentz-after");
          $("#main-content").addClass("contentz");
          // $("#anim_page").removeClass("animate__animated");
          // $("#anim_page").removeClass("animate__zoomIn");
          // this.$router.push('/book/0');
          // location.reload();
          window.location.href = "/menu";
        }, 1);
        // $("#page-1").removeClass("flipped");
        // $("#page-1").addClass("no-anim");
      } else {
        this.newVal = val;
        if (this.newVal - 1 == this.oldVal || this.oldVal - 1 == this.newVal) {
          // console.log(this.newVal);
          // console.log(this.oldVal);
          if (this.oldVal < this.newVal) {
            $("#page-" + val).removeClass("no-anim");
            $("#page-" + val).addClass("flipped");
          } else if (this.oldVal > this.newVal) {
            $("#page-" + this.oldVal).removeClass("flipped");
            $("#page-" + this.oldVal).addClass("no-anim");
          }
        } else {
          if (this.oldVal < this.newVal) {
            for (let index = 1; index <= this.newVal; index++) {
              $("#page-" + index).removeClass("no-anim");
              $("#page-" + index).addClass("flipped");
            }
          } else if (this.oldVal > this.newVal) {
            for (let index = 6; index > this.newVal; index--) {
              $("#page-" + index).removeClass("flipped");
              $("#page-" + index).addClass("no-anim");
            }
          }
        }
        // if (this.newVal - 1 != this.oldVal) {
        //   for (let index = 0; index <= this.newVal; index++) {
        //     $("#page-" + index).removeClass("no-anim");
        //     $("#page-" + index).addClass("flipped");
        //   }
        // } else if (this.oldVal - 1 != this.newVal) {
        //   console.log(this.newVal);
        //   for (let index = 0; index <= this.newVal; index++) {
        //     $("#page-" + index).removeClass("flipped");
        //     $("#page-" + index).addClass("no-anim");
        //   }
        // }
        this.oldVal = val;
        // this.menuNumber = val;
      }
    },
    getMenuDetails(val) {


      var item;





      this.categories = this.menuList.categories.filter(item => item.section_id === val);



    },
    
    openModal(image, multiple_value) {
      if (image == '') {
        this.multiple_img = multiple_value
        this.multiple = true;
      } else {
        this.multiple = false;
      }
      this.image = image;
      this.showModal = true;
    },
    closeModal() {
      this.image = null;
      this.showModal = false;
    },
    toggleRotate(event) {
      this.$refs.scrollableDiv.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      // Toggle the 'rotate' class on the clicked element
      event.target.classList.toggle('rotate');
    },
  },
};



// Add a delay before applying the class

</script>
<style scoped>
.mdl-box {
  position: fixed;
  width: 90%;
  background: #FDF8F5;
  z-index: 66666;
  top: 2%;
  right: 14px;
  padding: 22px;
  transition: 2s;
}

.mdl-box p {
  margin: 0;
  margin-top: 11px;
  /* color: #a6625b; */
  font-family: 'Montserrat';
  font-weight: 700;
}

.mdl_button {
  border: none;
  position: absolute;
  z-index: 66;
  background: #b9787a;
  /* color: #fff; */
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: -11px;
  right: -8px;
  font-size: 15px;
  line-height: 0;
}

/* The Modal (background) */
.modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.sidemenu {
  width: 20%;
  padding: 0;
}
.contentz-after {
  width: 80%;
}
#button-arrow {
  display: inline-block;
  background-color: #a6625b;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  line-height: 2pc;

}
#button-arrow::after {
  /* font-family: FontAwesome; */
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 33px;
  color: #fff;
}
#button-arrow:hover {
  cursor: pointer;
  background-color: #333;
}
#button-arrow:active {
  background-color: #555;
}
#button-arrow.show {
  opacity: 1;
  visibility: visible;
  background-color: #a6625b;
}
#button-arrow4 {
  display: inline-block;
  background-color: #a6625b;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  line-height: 2pc;

}
#button-arrow4::after {
  /* font-family: FontAwesome; */
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 33px;
  color: #fff;
}
#button-arrow4:hover {
  cursor: pointer;
  background-color: #333;
}
#button-arrow4:active {
  background-color: #555;
}
#button-arrow4.show {
  opacity: 1;
  visibility: visible;
  background-color: #a6625b;
}
#button-arrow5 {
  display: inline-block;
  background-color: #a6625b;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  line-height: 2pc;
}
#button-arrow5::after {
  /* font-family: FontAwesome; */
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 33px;
  color: #fff;
}
#button-arrow5:hover {
  cursor: pointer;
  background-color: #333;
}
#button-arrow5:active {
  background-color: #555;
}
#button-arrow5.show {
  opacity: 1;
  visibility: visible;
  background-color: #a6625b;
}
#button-arrow6 {
  display: inline-block;
  background-color: #a6625b;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  line-height: 2pc;

}
#button-arrow6::after {

  /* font-family: FontAwesome; */
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 33px;
  color: #fff;
}
#button-arrow6:hover {
  cursor: pointer;
  background-color: #333;
}
#button-arrow6:active {
  background-color: #555;
}
#button-arrow6.show {
  opacity: 1;
  visibility: visible;
  background-color: #a6625b;
}
#button-arrow7 {
  display: inline-block;
  background-color: #a6625b;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  line-height: 2pc;
}
#button-arrow7::after {

  /* font-family: FontAwesome; */
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 33px;
  color: #fff;
}
#button-arrow7:hover {
  cursor: pointer;
  background-color: #333;
}
#button-arrow7:active {
  background-color: #555;
}
#button-arrow7.show {
  opacity: 1;
  visibility: visible;
  background-color: #a6625b;
}
/* .viewbtn
{
    background: #a6625b;
    border: none;
    color: #fff;
    padding: 1px 25px;
    border-radius: 15px;
    margin-top: 6px;
    font-size: 13px;
} */
.viewbtn {
  background: no-repeat;
    border: 1px solid #a6625b;
    color: #a6625b;
    padding: 1px 25px;
    border-radius: 15px;
    margin-top: 6px;
    /* font-size: 13px !important; */
    font-weight: 700;
    font-size: 12px;
    color: #914846;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
}
.bg-success {
  --v-theme-overlay-multiplier: var(--v-theme-success-overlay-multiplier);
  background-color: #0000 !important;
  color: rgb(var(--v-theme-on-success)) !important;
  width: 25px;
  height: 25px !important;
  padding: 0;
  box-shadow: none;
}
.bg-info {
  --v-theme-overlay-multiplier: var(--v-theme-success-overlay-multiplier);
  background-color: #0000 !important;
  color: rgb(var(--v-theme-on-success)) !important;
  width: 25px;
  height: 25px !important;
  padding: 0;
  box-shadow: none;
}
.v-btn--elevated:hover,
.v-btn--elevated:focus,
.v-btn--elevated:active,
.v-btn--elevated:focus-visible {
box-shadow: none !important;
}
.v-btn--elevated:focus svg {
  fill: #000;
}
button:focus-visible {
  box-shadow: none !important;
}
#flush-collapse0{
  transition: transform 0.0s ease-in-out, opacity 0.0s ease-in-out;
}
.non-colored-button{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: none !important;
}
.v-expansion-panel{
  transition: .10s all cubic-bezier(0.4, 0, 0.2, 1);
  background-color: transparent;
  border-radius: 21px !important;
}
.v-panel-wake{
  display:block !important;
  text-align:center !important;
  background-color:#fed3d6;
  margin-top:20px;
  height:138px !important;
}
.v-expansion-panels{
  display:block !important;
}
.v-expansion-panel-title h3{
  color: #914846;
  /* margin: 16px; */
  letter-spacing: 1px;
  font-weight: 600;
  font-family: "new-spirit", serif;
  font-style: normal;
  font-size: 22px;
}
.v-expansion-panel-title h5{
text-transform: uppercase;
font-size: 13px;
color: #914846;
font-family: 'Montserrat', sans-serif;
font-weight: 400;
}

 .v-expansion-panels{
  position: unset !important;
 }
 .v-expansion-panel-title--active > .v-expansion-panel-title__overlay{
  color:none !important;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s ease-out;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px); /* Start from above */
  opacity: 0;
}
.v-panel-wake::before {
  content: "\2335"; /* Unicode character for some icon, can be changed */
  display: block;
  position: absolute;
  transition: transform .2s ease; /* Transition for smooth rotation */
  transform: rotate(0); /* Initial state: no rotation */
  left: 0;
  bottom: 20px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #B47575;
}
.v-expansion-panel-title--active::before{
  transform: rotate(180deg); /* Rotate the arrow 90 degrees */
  opacity: 5 ! important;
}
.menu-icon{
  position: absolute;
  left: -23px;
}
</style>
